<template>
  <div
    class="calendar-comp-cell-list"
  >
    <r-text
      v-for="item in listOf3"
      :key="item.title"
      type="caption"
      color-type="primary"
    >
      <calendar-marker
        :color="item.color"
        mini
      />
      {{ getDesc(item) }}
    </r-text>
    <r-text
      v-if="remainingCount"
      type="caption"
      center
    >
      + еще {{ remainingCount }}
    </r-text>
  </div>
</template>

<script>
export default {
  components: {
    calendarMarker: () => import('./calendar-marker')
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      index: 0
    }
  },
  computed: {
    parsedList() {
      const newList = {}

      this.list.forEach(e => {
        const cur = newList[e.event_type]

        if (cur) {
          cur.value++
        } else {
          newList[e.event_type] = {
            id: e.event_type,
            color: e.color,
            value: 1
          }
        }
      })

      return Object.values(newList)
    },
    listOf3() {
      return this.parsedList?.slice(0, 3) || []
    },
    remainingCount() {
      return this.parsedList?.slice(3)?.length || null
    }
  },
  methods: {
    getDesc(item) {
      return item.value > 1 ? `${item.id} (${item.value} шт)` : item.id
    }
  }
}
</script>

<style lang="scss">
.calendar-comp-cell-list {
  display: grid;
  overflow: hidden;
  height: 100%;

  .r-text {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
